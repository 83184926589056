body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('back.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100vh;
  top: 0px;
  bottom: 0px;
}

p {
  position: relative;
  font-size: 15px;
  text-align: left;
}

ul{
  text-align: left;
}

.title{
  font-size: 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.youtube{
  position: absolute;
}

.tint{
  position: absolute;
  width: 100vw;
  height: 100%;
  background-color: rgba(225, 225, 225, 0.6);
}

.logos{
  position: relative;
  display: inline-block;
  margin-top: 25vh;
}

.appicon{
  border-radius: 25%;
  width: 200px;
  height: 200px;
}
.appicon.small{
  width: 100px;
  height: 100px;
  padding: 40px;
  border-radius: 35%;
}

.logo{
  background-color: rgba(255, 255, 255, 1.0);
  position: absolute;
  margin-left: -50px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: 120px;
}

.centerparent{
  max-width:600px;
  margin: 0 auto;
  padding-top: 20px;
}

.badgecontainer{
  width: 200px;
  position: relative;
  display: inline-block;
  padding: 20px;
  margin-top: 40px;
}

.badge{
  margin: 0;
  margin-left: -125px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

footer{
  position: absolute;
  bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

a{
  text-decoration: none;
  color: black;
}

.footerlink{
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.cell{
  max-width: 90%;
  min-width: 200px;
  position: relative;
  display: inline-block;
  margin-top: 15vh;
  background-color: white;
  border-radius: 20px;
  margin-bottom: 100px;

}

.textbody{
  padding: 40px;
  padding-top: 0px;
  margin-top: -20px;
}
